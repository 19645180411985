<template>
  <v-card
    style="min-height: 100vh"
    class="bg-light"
  >
    <v-app-bar 
      app 
      flat 
      fixed 
      outlined
      dense 
      light
      color="#ffffff"
      elevation="0.5"
    >
      <v-icon @click="close()">mdi-close</v-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title class="text-body-2 text-center font-weight-6">
        Invoice# : <span class="primary--text" v-if="order">{{ order.order_id }}</span><br/>
        <small 
          class="transaction-date font-weight-6 blue--text text--darken-1" 
          v-if="order"
        >{{ transactionDate }}</small>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>

        <v-list nav dense>
          <v-list-item-group color="primary">
            <v-list-item @click="viewTracking()" v-if="order && order.trans_type === 'delivery'">
              <v-list-item-content>
                <v-list-item-title class="text-caption">
                  <v-icon small class="mr-3">mdi-map-marker-radius</v-icon>Tracking
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="reorder()" v-if="order && order.trans_type !== 'dinein'">
              <v-list-item-content>
                <v-list-item-title class="text-caption">
                  <v-icon small class="mr-3">mdi-food</v-icon>Reorder
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-caption">
                  <v-icon small class="mr-3">mdi-share-variant</v-icon>
                  Share
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-sheet class="receipt-sheet" v-if="order">
      <v-alert
        v-if="alert"
        class="alert text-h6 font-weight-6 p-3 mt-6"
        type="success"
        transition="fade-transition"
        >Order successful!</v-alert
      >
      <v-container class="px-2 pb-10 mb-6">
        <v-row>
          <div class="px-3">
            <v-col cols="12">
              <center class="px-5 mb-8">
                <img :src="merchantLogo" class="w-25" />
                <div class="text-h4 font-weight-bold">
                  {{ order.restaurant_name }}
                </div>
                <small class="tax-number font-weight-thin" v-if="order.tax_number">
                  (<span class="font-weight-6">SST No:</span> <span class="blue--text text--darken-1">{{ order.tax_number }}</span>)
                </small>
                <div class="text-subtitle-2 font-weight-6 mt-3 mb-5">
                  {{ merchantAddress }}
                </div>
                <div class="mb-1">For any enquiries,</div>
                <div class="text-h6 font-weight-6">
                  Call Us: <a :href="'tel: ' + order.restaurant_phone" class="text-decoration-none blue--text">{{ order.restaurant_phone }}</a>
                </div>
              </center>

              <section>
                <div class="text-subtitle-1 font-weight-6 my-1">
                  <v-row class="p-0 m-0">
                    <v-col cols="10" class="p-0 m-0">
                      {{ order.trans_type | capitalize }} <small class="text-body-2 mr-1">@</small> 
                      <small class="text-body-2 font-weight-6" v-if="order.trans_type !== 'dinein'">
                        {{ toDateString(order.delivery_date) }} ({{ toTimeString(order.delivery_time, 'HH:mm') }})
                      </small>
                      <small class="text-h6 font-weight-6 light-blue--text" v-else>
                        {{ order.dinein_table_number }}
                      </small>
                    </v-col>
                    <v-col cols="2" class="text-right p-0 m-0">
                      <v-avatar size="30">
                        <v-img
                          v-if="isCashless"
                          :alt="`${order.payment_type}`"
                          :src="
                            require(`@/assets/images/payment-method-${order.payment_type}.png`)
                          "
                        ></v-img>
                        <v-icon v-if="isCOD" color="primary"
                          >mdi-account-cash</v-icon
                        >
                        <v-icon v-if="isPayAtCounter" color="primary"
                          >mdi-cash-register</v-icon
                        >
                      </v-avatar>
                    </v-col>
                  </v-row>
                </div>
              </section>
              <section class="mb-4">
                <div class="text-body-2 text--darken-3 font-weight-6 mt-2">
                  <span v-if="isDelivery">Deliver to:</span>
                  <span v-if="isPickup">Pickup by:</span>
                </div>
                <div class="text-caption grey--text text--darken-3 font-weight-bold ml-3 mt-2" v-if="isDelivery">
                  {{ order.address.contact_name }} ({{ order.address.contact_phone }})
                </div>
                <div class="text-caption grey--text text--darken-3 font-weight-bold ml-3 mt-2" v-if="isPickup">
                  {{ order.last_name + ' ' + order.first_name}} ({{ order.contact_phone }})
                </div>
                <div class="text-caption font-weight-6 ml-3 mt-2" v-if="isDelivery && order.address">
                  {{ order.address.location_name }}
                </div>
                <div class="text-caption font-weight-5 ml-3 mt-1" v-if="isDelivery && order.address">
                  {{ order.address.formatted_address }}
                </div>
              </section>

              <section class="mb-4" v-if="order.delivery_instruction">
                <div class="text-body-2 font-weight-6 mt-2">Instructions:</div>
                <div class="text-caption ml-3">{{ order.delivery_instruction }}</div>
              </section>

              <section class="mt-6">
                <v-divider class="table-divider my-2"></v-divider>
                <v-row no-gutters class="text-caption font-weight-bold">
                  <v-col cols="7">Description</v-col>
                  <v-col class="text-center" cols="1">Qty</v-col>
                  <v-col class="text-right" cols="2">Price (RM)</v-col>
                  <v-col class="text-right" cols="2">Total (RM)</v-col>
                </v-row>
                <v-divider class="table-divider my-2"></v-divider>
                <v-row class="text-caption mt-2" no-gutters v-for="item in order.details" :key="item.name">
                  <v-col cols="7">
                    <span class="font-weight-6">
                      {{ item.name }}
                      <v-chip
                        class="ml-1"
                        color="success"
                        x-small
                        v-if="item.is_takeaway"
                      >TA</v-chip>
                      <v-chip
                        class="ml-1"
                        color="error"
                        x-small
                        v-if="item.is_serve_later"
                      >SL</v-chip>
                    </span>
                    <br/>
                    <small class="item-size teal--text p-0 m-0 mt-3 ml-1">
                      <v-icon size="12" dense color="#00897B" class="mr-1 mb-1">mdi-food-variant</v-icon>
                      {{ item.size }}
                      <br/>
                    </small>
                    <small class="font-italic font-weight-thin ml-1" v-if="item.order_notes">
                      <v-icon x-small dense color="#78909c" class="mr-1">mdi-comment-quote</v-icon>{{ item.order_notes }}
                    </small>
                  </v-col>
                  <v-col class="text-center" cols="1">{{ item.qty }}</v-col>
                  <v-col class="text-right" cols="2">{{ (+item.discounted_price).toFixed(2) }}</v-col>
                  <v-col class="text-right" cols="2">{{ (+item.discounted_price * +item.qty).toFixed(2) }}</v-col>
                  <template v-for="addOn in parseJson(item.addon)">
                    <v-col
                      cols="7"
                      class="mt-1 pl-2" 
                      :key="`addon-name-${addOn.addon_name}`"
                    >
                      - {{ addOn.addon_name }}</v-col
                    >
                    <v-col 
                      cols="1" 
                      class="text-center"
                      :key="`addon-qty-${addOn.addon_name}`">
                      {{ (addOn.addon_qty ? (+item.qty * +addOn.addon_qty) : addOn.addon_qty) }}
                    </v-col>
                    <v-col
                      cols="2"
                      class="text-right"
                      :key="`addon-price-${addOn.addon_name}`"
                    >
                      {{ addOn.addon_price ? (+addOn.addon_price).toFixed(2) : '' }}
                    </v-col>
                    <v-col
                      cols="2"
                      class="text-right"
                      :key="`addon-total-${addOn.addon_name}`"
                    >
                      {{ (+addOn.addon_price * +(addOn.addon_qty || 1) * +item.qty).toFixed(2) }}
                    </v-col>
                  </template>
                </v-row>
                <v-divider class="table-divider my-2"></v-divider>
                <v-row class="text-caption font-weight-6 mt-1" no-gutters>
                  <v-col cols="9">Subtotal <small v-if="order.is_menu_include_tax">( excl. SST )</small>:</v-col>
                  <v-col class="text-right" cols="3">
                    {{ $n(subTotal, 'currency', 'en-MY') }}
                  </v-col>
                </v-row>
                <v-row class="text-caption font-weight-6 mt-1" no-gutters v-if="+order.discounted_amount > 0">
                  <v-col cols="8">Discount (<small>{{ +order.discount_percentage }}%</small>):</v-col>
                  <v-col class="text-right">
                    - {{ $n(order.discounted_amount, 'currency', 'en-MY') }}
                  </v-col>
                </v-row>
                <v-row class="text-caption font-weight-6 mt-1" no-gutters v-if="isDelivery">
                  <v-col cols="9">
                    Delivery Fees <small v-if="order.delivery_discount > 0">(- {{ $n(order.delivery_discount, 'currency', 'en-MY') }})</small>:
                  </v-col>
                  <v-col class="text-right">
                    {{ $n(order.delivery_charge, 'currency', 'en-MY') }}
                  </v-col>
                </v-row>
                <v-row class="text-caption font-weight-6 mt-1" no-gutters v-if="order.packaging">
                  <v-col cols="9">Packaging Fee:</v-col>
                  <v-col class="text-right" cols="3">
                    {{ $n(order.packaging, 'currency', 'en-MY') }}
                  </v-col>
                </v-row>
                <v-row class="text-caption font-weight-6 mt-1" no-gutters v-if="+order.service_charge > 0">
                  <v-col cols="9">Service Charge (<small>{{ +order.service_charge }}%</small>)</v-col>
                  <v-col class="text-right" cols="3">
                    {{ $n(order.service_charge_total, 'currency', 'en-MY') }}
                  </v-col>
                </v-row>
                <v-row class="text-caption font-weight-6 mt-1" no-gutters v-if="+order.tax > 0">
                  <v-col cols="9">Tax (<small>SST {{ +order.tax * 100 }}%</small>)</v-col>
                  <v-col class="text-right" cols="3">
                    {{ $n(order.taxable_total, 'currency', 'en-MY') }}
                  </v-col>
                </v-row>
                <v-row class="text-caption font-weight-6 mt-1" no-gutters v-if="+order.voucher_amount">
                  <v-col cols="9">Voucher (<small>{{ order.voucher_code }}</small>):</v-col>
                  <v-col class="text-right" cols="3">
                    - {{ $n(lessVoucher, 'currency', 'en-MY') }}
                  </v-col>
                </v-row>
                <v-row class="text-caption font-weight-6 mt-1" no-gutters v-if="order.rounding > 0">
                  <v-col cols="9">Rounding:</v-col>
                  <v-col class="text-right" cols="3">
                    {{ $n(order.rounding, 'currency', 'en-MY') }}
                  </v-col>
                </v-row>
                <v-row class="text-h6 font-weight-bold mt-4" no-gutters>
                  <v-col cols="8">
                    <span>TOTAL</span><span v-if="+order.tax > 0">( incl. SST )</span>
                  </v-col>
                  <v-col class="text-right" cols="4">
                    {{ $n(order.total_w_tax, 'currency', 'en-MY') }}
                  </v-col>
                </v-row>
                <v-divider class="table-divider my-2"></v-divider>
              </section>
            </v-col>
          </div>
        </v-row>
      </v-container>
      <Largeordermodal
        v-if="dialog"
        v-model="dialog"
        :isHistoryDialog="false"
        :orderid="order.order_id"
        :status="order.status"
      >
      </Largeordermodal>
    </v-sheet>
    <v-bottom-navigation
      class="text-body-1 text-center align-center white--text font-weight-bold"
      style="cursor: pointer;"
      background-color="primary"
      fixed
      grow
      v-if="canAddOrder"
    >
      <span @click="$router.push({ name: 'dinein', params: { 'tableNo': ($cookies.get('table-no') || '') } }).catch(() => {})">
      Add Order
      </span>
    </v-bottom-navigation>
    <bottomnav :fixed="true" v-else></bottomnav>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import Largeordermodal from '../components/DialogModals/AcceptedOrderModal';
import bottomnav from '../components/partials/mainBottomnav';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

export default {
  components: {
    Largeordermodal,
    bottomnav,
  },
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      dialog: false,
      alert: false,
      selected: 0,
    };
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapState('order', {
      order: (state) => state.order,
    }),
    ...mapState('customer', {
      customer: (state) => state.customer,
    }),
    ...mapGetters('customer', {
      customerName: 'getName',
    }),
    ...mapGetters('order', {
      isDelivery: 'isDelivery',
      isPickup: 'isPickup',
      isDinein: 'isDinein',
      isCOD: 'isCOD',
      isPayAtCounter: 'isPayAtCounter',
      isCashless: 'isCashless',
      lessVoucher: 'getVoucherAmount',
    }),
    merchantLogo() {
      return this.order?.logo_s3
        ? `${this.cdnUrl}/${this.order?.logo_s3}`
        : `${this.legacyCdnUrl}/upload/${this.order?.logo}`;
    },
    merchantAddress() {
      return `${this.order?.street}, ${this.order?.post_code} ${this.order?.city}, ${this.order?.state}`;
    },
    transactionDate() {
      return dayjs(this.order?.date_created).utc().format('YYYY-MM-DD HH:mm:ss');
    },
    subTotal() {
      return this.order?.sub_total - (this.order?.is_menu_include_tax ? this.order?.taxable_total : 0);
    },
    canAddOrder() {
      return this.order?.trans_type === 'dinein' && this.$route.query?.time && (dayjs(+this.$route.query?.time).add(3, 'hour').isSameOrAfter(dayjs()));
    },
  },
  methods: {
    ...mapActions('order', ['getReceipt']),
    ...mapMutations('cart', ['RESET_CART']),
    toDateString(date) {
      return dayjs(date).format('YYYY-MM-DD');
    },
    toTimeString(date, format) {
      return dayjs(date, format).format('hh:mm a');
    },
    parseJson(string) {
      return JSON.parse(string || '{}');
    },
    viewTracking() {
      this.$gtag.event('view_tracking', {
        'event_category': 'navigation',
        'event_label': 'View Tracking',
        'event_value': `${this.order.restaurant_name} (${this.order.order_id}) ordered at ${this.order.date_created} is ${this.order.status}`, 
      });

      this.dialog = true;
    },
    reorder() {
      window.location = `https://${this.order.restaurant_hostname}/menu?service=${this.order.trans_type}`;
    },
    close() {
      if (this.$route.query?.time || !this.prevRoute?.name) {
        this.$router.push({ name: this.order.trans_type === 'dinein' ? 'scan' : 'menu' });
      } else {
        this.$router.go(-1);
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.prevRoute = from);
  },
  async mounted() {
    dayjs.extend(customParseFormat);
    dayjs.extend(utc);
    dayjs.extend(isSameOrAfter);

    if (!this.$route.params?.token) {
      this.$router.push({ name: 'menu' });
    }

    this.RESET_CART();

    try {
      await this.getReceipt({
        token: this.$route.params.token,
        query: { merchantId: this.profile.merchant_id, details: 1, address: 1, history: 1 },
      });
    } catch(e) {
      this.$router.push({ name: 'menu' });
    }

    if ((this.$route.query?.time && (dayjs(+this.$route.query?.time).add(3, 'hour').isSameOrAfter(dayjs()))) || !this.prevRoute?.name) {
      this.alert = true;
      setTimeout(() => this.alert = false, 3000);
    }
  },
};
</script>

<style scoped>
.v-toolbar {
  box-shadow: 0px 2px 4px -1px rgb(255 99 71 / 20%),
    0px 4px 5px 0px rgb(255 99 71 / 14%), 0px 1px 10px 0px rgb(255 99 71 / 12%) !important;
}
.table-divider {
  /* border-top: 0.55px solid rgba(0, 0, 0, .2); */
  border-color: rgba(0, 0, 0, .2);
}
.v-item-group > .v-list-item:hover {
  color: #ffffff !important;
  background-color: rgb(255 99 71 / 80%);
  font-weight: bold !important;
}
.alert {
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  width: 90vw;
}
.tax-number {
  font-size: 9px;
}
.transaction-date {
  font-size: 9px;
}
@media screen and (max-width: 1023px) {
  .receipt-sheet {
    margin-top: 54px;
    overflow: hidden;
  } 
}
@media screen and (min-width: 1024px) {
  .alert {
    width: 374px !important;
  }
}
</style>
